<template>
  <div class="banner-table">
    <v-simple-table>
      <thead>
        <tr class="banner-table__header">
          <th>Preview</th>
          <th>Name</th>
          <th>Source</th>
          <th>Created</th>
          <th />
        </tr>
      </thead>
      <tbody class="banner-table__content">
        <template v-if="banners.length > 0">
          <tr v-for="(item, i) in banners" :key="i">
            <td style="max-width: 132px">
              <v-img
                :src="sourceImage(item)"
                lazy-src="@/assets/channels/banners.jpg"
                alt="thumbnail"
                class="banner-logo my-4"
                style="cursor: pointer; max-width: 100px; max-height: 100px;padding-top: 6px"
                @click="
                  $emit('image-preview', { name: item.name, image: item.imageFileUrls })
                "
              >
                <template #placeholder>
                  <v-img
                    src="@/assets/default-thumbnail.png"
                    alt="thumbnail"
                    class="banner-logo my-4"
                    max-height="95px"
                  />
                </template>
              </v-img>
            </td>
            <td class="py-1">
              <div class="banner-table__banner-header mb-1">
                {{ item.name }}
                <div class="text--disabled" style="font-size: 11px;">
                  ID: {{ item.id }}
                </div>
                <v-chip v-for="(tag, j) in item.tags" :key="j" class="rectangle">
                  {{ tag.tag.name }}
                </v-chip>
              </div>
            </td>
            <td class="py-1">
              <div class="banner-table__banner-header mb-1">
                {{ item.sourceName }}
              </div>
            </td>
            <td class="py-1">
              <div class="banner-table__banner-subheader">
                <div
                  class="pt-1"
                  :title="getDateWithDayNameAndTime(item.createdAt)"
                >
                  <span class="font-weight-bold black--text">Created: </span>
                  {{ getDate(item.createdAt) }}
                </div>
                <div
                  class="pt-1"
                  :title="getDateWithDayNameAndTime(item.updatedAt)"
                >
                  <span class="font-weight-bold black--text">Updated: </span>
                  {{ getDate(item.updatedAt) }}
                </div>
              </div>
            </td>

            <td class="v-menu-attach-parent">
              <v-menu
                v-if="optionListItems.length"
                nudge-top="-5px"
                nudge-left="15px"
                :offset-y="true"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="elevation-0 outlined-btn px-12"
                    height="34px"
                    plain
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('click')"
                  >
                    Actions
                  </v-btn>
                </template>
                <OuterList
                  title="Actions"
                  :items="optionListItems"
                  @click="$emit('action', { event: $event, item })"
                />
              </v-menu>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="4">
              <div>
                Currently you do not have any banners uploaded. To get started,
                click the button above!
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import OuterList from "@/sharedComponents/OuterList";
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "BannerTable",
  components: { OuterList },
  mixins: [datesMixin],
  props: {
    banners: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      menuOpened: false,
    };
  },
  computed: {
    optionListItems() {
      if (this.$store.getters['user/hasWritePermission']) {
        return [
          { label: "Edit item", icon: "mdi-pencil", value: "edit" },
          { label: "Archive Item", icon: "mdi-delete-outline", value: "delete" },
        ];
      }

      return [];
    },
  },
  methods: {
    sourceImage(item) {
      if (item.imageFile && item.imageFileUrls.small) {
        return item.imageFileUrls.small;
      } else {
        return require("@/assets/channels/banners.jpg");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.banner-logo {
  max-height: 100px;
}

.banner-table {
  // .v-data-table {
  &__header {
    th {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
    }
  }
  &__banner-header {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    color: #241c15;
  }
  &__banner-subheader {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    line-height: normal;
    color: #66788e;
  }
  .v-menu-button:focus {
    background: #2b84eb !important;
    opacity: 1;
    .v-btn__content {
      color: white;
    }
  }
  &__content {
    tr td {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif !important;
      * {
        font-size: 13px;
        color: #241c15;
        font-family: "Open Sans", sans-serif;
      }
    }
  }

  thead {
    tr > th {
      border-bottom: solid 1px #dee7ee !important;
    }
  }
  tbody {
    // table border colors
    tr > td {
      border-bottom: solid 1px #dee7ee;
    }
    tr:hover {
      background: inherit !important;
    }
  }
  // }

  .panel-button {
    font-family: "Open Sans", sans-serif;
    font-size: 13px !important;
    color: #66788e;
    text-transform: none;
    background: #ecf1f5 !important;
  }
  .panel-button[aria-expanded="true"] {
    color: white;
    background: #2b84eb !important;
    opacity: 1 !important;
  }

  .rectangle {
    font-family: OpenSans;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2b84eb;
    background-color: #DDEEF9;
    margin-right: 6px;
    margin-top: 4px;
    height: 21px;
  }
}
</style>
