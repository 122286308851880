<template>
  <v-container class="mt-3 px-sm-10 px-3 segments-accordion elevation-0" fluid>
    <PageHeader
      header-text="Banners"
      regular-button-text="New Banner"
      :regular-button-visible="$store.getters['user/hasWritePermission']"
      @regular-button-click="dialog = true"
    />

    <SearchFieldRowWithPagination
      search-field-placeholder="Search for banners"
      :current-page-number="banners_page_number"
      :current-page-size="banners_page_size"
      :count-total="banners_total"
      :page-sizes="[25, 50, 100]"
      :init-search-string="search_string"
      @next-page="get_banners_next_page"
      @prev-page="get_banners_prev_page"
      @change-page-size="banners_change_page_size"
      @search="search_string => on_search(search_string)"
    />

    <BannerTable
      :banners="banners"
      @action="handleAction($event)"
      @image-preview="openPreviewImage($event)"
    />

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="560px"
      @click:outside="edited_banner = {}"
    >
      <BannerEdit
        v-if="dialog"
        :banner-item="edited_banner"
        :available-tags="tagNames"
        :sources="sources"
        @save="save_banner"
        @dismiss="
          dialog = false;
          edited_banner = {};
        "
      />
    </v-dialog>

    <v-dialog v-model="previewImageDialog" scrollable>
      <ImagePreviewDialog
        v-if="previewImageDialog"
        :image-url="previewImageUrl"
        :full-image-url="previewFullImageUrl"
        :name="previewImageName"
        @dismiss="previewImageDialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import ImagePreviewDialog from "@/sharedComponents/ImagePreviewDialog";
import SearchFieldRowWithPagination from "@/sharedComponents/SearchFieldRowWithPagination";
import BannerTable from "@/views/Channels/components/BannerTable";
import BannerEdit from "@/views/Channels/components/BannerEdit";
import { hasError } from "@/mixins/has_error";

export default {
  name: "Banner",
  metaInfo: {
    title: 'Banner Creatives'
  },
  components: {
    BannerEdit,
    BannerTable,
    SearchFieldRowWithPagination,
    PageHeader,
    ImagePreviewDialog,
  },
  mixins: [hasError],
  data() {
    return {
      edited_banner: {},
      dialog: false,
      banners: [],
      banners_total: 0,
      banners_page_number: 1,
      banners_page_size: 25,
      search_string: '',
      tags: [],
      tagNames: [],
      sources: [],
      previewImageDialog: false,
      previewImageUrl: null,
      previewFullImageUrl: null,
      previewImageName: null,
    };
  },
  created() {
    this.get_available_banners(this.$route.query.keyword);
    this.getBannerTags();
    this.getSources();
  },
  methods: {
    openPreviewImage({ name, image }) {
      this.previewImageUrl = image.medium || image.large || image.full || image.small;
      this.previewFullImageUrl = image.full || image.large || image.medium || image.small;
      this.previewImageName = name;
      this.previewImageDialog = !!this.previewImageUrl;
    },

    async handleAction(action) {
      if (action.event.value === "delete") {
        await this.$rest.banners.put_resource(action.item.id, {
          ...action,
          isArchived: true,
        });
        await this.get_available_banners();
      } else if (action.event.value === "edit") {
        this.edited_banner = { ...action.item };
        await this.getTagsOfBanner();
        this.dialog = true;
      }
    },
    async on_search(search_string) {
      this.search_string = search_string;
      await this.get_available_banners(search_string);
    },
    async save_banner(banner) {
      // pre-processing - parse tags
      banner.tagItems = banner.tagItems.map(tag => {
        const tagItem = this.tags.find(
          tagInstance => tagInstance.name.toLowerCase() === tag.toLowerCase()
        );

        return tagItem
          ? { id: tagItem.id } // use existing tag ID
          : { name: tag }; // create new tab
      });

      if (banner.id) {
        await this.$rest.banners.put_resource(banner.id, banner);
        this.$notifier.success("Successfully edited the banner");
      } else {
        await this.$rest.banners.post_resource(banner);
        this.$notifier.success("Successfully added the banner");
      }
      this.edited_banner = {};
      this.dialog = false;
      await this.get_available_banners();
      await this.getBannerTags();
    },
    async banners_change_page_size(size) {
      this.banners_page_size = size;
      this.banners_page_number = 1;
      await this.get_available_banners();
    },
    async get_banners_next_page() {
      this.banners_page_number += 1;
      await this.get_available_banners();
    },
    async get_banners_prev_page() {
      this.banners_page_number -= 1;
      await this.get_available_banners();
    },
    async get_available_banners(search_string) {
      if (search_string) {
        this.search_string = search_string;
      } else {
        this.search_string = "";
      }
      const resp = await this.$rest.banners.get_collection({
        limit: this.banners_page_size,
        page: this.banners_page_number,
        name: this.search_string,
        sort: ['createdAt:desc'],
        groups: ["tag_creative:get"],
        isArchived: false,
      });
      this.banners = resp.data.items.map(item => ({
        ...item,
        sourceName:
          this.sources.find(source => source.id === item.sourceId)?.name || ''
      }));
      this.banners_total = resp.data.totalCount;
    },
    async getBannerTags() {
      const resp = await this.$rest.tags
        .getCollection({
          fields: ['id', 'name'],
          ignorePagination: true
        })
        .catch(this.showGenericError);

      if (!resp.data) {
        return;
      }

      this.tags = [...resp.data.items];
      this.tagNames = this.tags.map(item => item.name);
    },
    async getTagsOfBanner() {
      const response = await this.$rest.banners
        .getTags(this.edited_banner.id)
        .catch(this.showGenericError);

      if (!response.data) {
        return;
      }

      this.edited_banner.tags = response.data.items.map(
        tagInfo => tagInfo.tag.name
      );
    },
    async getSources() {
      this.sources = (await this.$rest.source.get_collection({
        ignorePagination: true,
        sort: ["name:asc"]
      })).data.items;
    }
  }
};
</script>
