<template>
  <v-card class="popup-card">
    <v-card-title class="px-6 pt-4 pb-4">
      <div v-if="id !== undefined && id !== null">
        Edit Banner
      </div>
      <div v-else>
        Create Banner
      </div>
    </v-card-title>
    <v-divider v-if="$vuetify.breakpoint.xsOnly" />
    <v-card-text class="px-6 pt-5">
      <validation-observer ref="textInput">
        <v-row>
          <v-col cols="5">
            <CustomTextInput
              v-model="name"
              header="Banner Name"
              class="flex-grow-1"
              placeholder="banner 300x300 google"
              :maxlength="200"
              required
            />
          </v-col>
          <v-col cols="7">
            <CustomDropdown
              v-model="selectedSource"
              header="Source"
              placeholder="Select source"
              :items="available_sources"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <CustomFileUpload
              v-model="imageFile"
              placeholder="Select Preview Image"
              header="Preview Image"
              accept="image/*"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <TagsInput
              v-model="tags"
              :items="availableTags"
              header="Tags"
              placeholder="Select / Add Tag(s) for this Creative"
            />
          </v-col>
        </v-row>
      </validation-observer>
      <v-card-actions class="d-flex justify-end align-center pa-0 mt-5">
        <div
          class="simple-text-button button--gray px-9"
          @click="$emit('dismiss')"
        >
          Cancel
        </div>

        <v-btn
          class="custom-button custom-button--blue px-15"
          height="34px"
          depressed
          @click="on_save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomFileUpload from "@/sharedComponents/CustomFileUpload";
import TagsInput from "@/sharedComponents/TagsInput";

export default {
  name: "BannerEdit",
  components: {
    TagsInput,
    CustomTextInput,
    CustomDropdown,
    CustomFileUpload,
  },
  props: {
    bannerItem: {
      default: () => ({})
    },
    availableTags: {
      type: Array,
      default: () => [],
    },
    sources: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      available_sources: [],
      selectedSource: null,
      defaultSource: null,
      id: null,
      name: "",
      image: null,
      imageFile: null,
      tags: [],
    };
  },
  created() {
    this.id = this.bannerItem.id;
    this.name = this.bannerItem.name;
    this.tags.push(...(this.bannerItem?.tags ?? []));
    if (this.bannerItem.sourceId !== undefined) {
      this.selectedSource = this.bannerItem.sourceId;
    }
    this.get_available_sources();
  },
  methods: {
    on_save() {
      const payload = {
        id: this.id,
        name: this.name,
        source: this.selectedSource,
        requestImageFile: this.imageFile,
        tagItems: this.tags,
      };

      this.$emit('save', payload);
    },
    async get_available_sources() {
      this.available_sources = this.sources.map(i => ({
        label: i.name,
        value: i.id
      }));

      this.defaultSource = this.sources.find(
        ({ isDefault }) => isDefault === true
      ).id;
      if (!this.selectedSource) {
        this.selectedSource = this.defaultSource;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.popup-card {
  .v-input--checkbox {
    margin-top: 4px;

    .v-label {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: 600;
      color: #241c15;
    }
    .v-icon {
      font-size: 18px;
    }
    .v-messages {
      display: none;
    }
  }
}
</style>
